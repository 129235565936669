/*
 * headline.scss
 *
 * 見出し用コンポーネント
 * 定義する際は、接頭辞としてttl_を付けること。
 */
.ttl_frame {
  font-weight: 500;
  position: relative;
  letter-spacing: .05em;
  border-top: 1px solid $c_border;
  border-bottom: 1px solid $c_border;
  @include responsive((
    font-size: 56,
    line-height: 96,
    padding: 48 16,
    margin-bottom: 96,
    margin-top: 320,
  ));
  @include mq(sp) {
    font-size: mycalcSP(40);
    line-height: mycalcSP(64);
    padding: mycalcSP(48 16);
    margin-bottom: mycalcSP(80);
    margin-top: mycalcSP(208);
  }
}

.ttl_underline {
  width: 100%;
  font-weight: 500;
  position: relative;
  letter-spacing: .05em;

  @include responsive((
    font-size: 48,
    line-height: 72,
    padding-bottom: 48,
    margin-bottom: 80,
    margin-top: 160,
  ));
  @include mq(sp) {
    font-size: mycalcSP(36);
    line-height: mycalcSP(56);
    padding-bottom: mycalcSP(40);
    margin-bottom: mycalcSP(80);
    margin-top: mycalcSP(128);
  }

  &:after {
    width: 100%;
    background: $c_border;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    @include responsive((
      height: 2,
    ));

    @include mq(sp) {
      height: mycalcSP(2);
    }
  }
}

.ttl_beta {
  width: 100%;
  font-weight: 500;
  position: relative;
  color: $c_white;
  background: $c_gray_dark;
  box-sizing: border-box;
  letter-spacing: .05em;

  @include responsive((
    font-size: 40,
    line-height: 60,
    padding: 32,
    border-radius: 8,
    margin-bottom: 80,
    margin-top: 160,
  ));

  @include mq(sp) {
    font-size: mycalcSP(32);
    line-height: mycalcSP(48);
    padding: mycalcSP(32);
    border-radius: mycalcSP(8);
    margin-bottom: mycalcSP(80);
    margin-top: mycalcSP(128);
  }
}

.ttl_bar {
  font-weight: 500;
  position: relative;
  letter-spacing: .05em;
  @include responsive((
    font-size: 36,
    line-height: 56,
    padding: 16 0 16 32,
    margin-bottom: 80,
    margin-top: 160,
  ));
  @include mq(sp) {
    font-size: mycalcSP(30);
    line-height: mycalcSP(48);
    padding: mycalcSP(8 0 8 32);
    margin-bottom: mycalcSP(80);
    margin-top: mycalcSP(128);
  }

  &:before {
    content: '';
    background: $c_border;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    @include responsive((
      width: 8,
    ));
    @include mq(sp) {
      width: mycalcSP(8);
    }
  }
}

.ttl_bold {
  width: 100%;
  font-weight: 500;
  position: relative;
  letter-spacing: .05em;

  @include responsive((
    font-size: 32,
    line-height: 48,
    margin-bottom: 80,
    margin-top: 160,
  ));

  @include mq(sp) {
    font-size: mycalcSP(28);
    line-height: mycalcSP(44);
    margin-bottom: mycalcSP(80);
    margin-top: mycalcSP(128);
  }

}

.ttl_sec {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
  margin: 0 auto;
  @include responsive((
    max-width: 2400,
    margin-bottom: 96,
  ));
  @include mq(sp) {
    width: mycalcSP(670);
    max-width: 100%;
    margin: 0 auto mycalcSP(96);
  }

  &_ja {
    font-weight: 700;
    letter-spacing: 0;
    font-family: $f_en;
    @include responsive((
      font-size: 56,
      line-height: 96,
      padding-left: 32,
    ));
    @include mq(sp) {
      font-size: mycalcSP(44);
      line-height: mycalcSP(72);
      padding-left: 0;
    }

    .sub > & {
      letter-spacing: .1em;
      @include responsive((
        font-size: 48,
        line-height: 64,
      ));
      @include mq(sp) {
        font-size: mycalcSP(36);
        line-height: mycalcSP(48);
      }
    }

    small {
      font-weight: inherit;
      @include responsive((
        font-size: 36,
        line-height: 56,
      ));
      @include mq(sp) {
        font-size: mycalcSP(32);
        line-height: mycalcSP(44);
      }
    }
  }

  &_en {
    order: -1;
    font-family: $f_en;
    position: relative;
    color: $c_gray;
    white-space: nowrap;
    letter-spacing: 0;
    @include responsive((
      font-size: 320,
      line-height: 320,
      margin-bottom: 32,
    ));
    @include mq(sp) {
      font-size: mycalcSP(112);
      line-height: mycalcSP(112);
      margin-bottom: mycalcSP(32);
      padding-left: 0;
    }

    .sub > & {
      @include responsive((
        font-size: 192,
        line-height: 208,
        margin-bottom: 16,
        padding-left: 32,
      ));
      @include mq(sp) {
        font-size: mycalcSP(80);
        line-height: mycalcSP(80);
        margin-bottom: mycalcSP(16);
        padding-left: 0;
      }
    }
  }
}

.ttl_ico,
.ttl_logo,
.ttl_reccomend {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  @include responsive((
    padding-left: 48,
    margin-bottom: 48,
  ));
  @include mq(sp) {
    padding-left: 0;
    margin-bottom: mycalcSP(48);
  }

  &_txt {
    font-weight: bold;
    letter-spacing: 0;
    font-family: $f_en;
    @include responsive((
      font-size: 48,
      line-height: 80,
    ));

    @include mq(sp) {
      width: mycalcSP(614);
      font-size: mycalcSP(36);
      line-height: mycalcSP(48);
    }
  }

  &_img {
    order: -1;
    @include responsive((
      margin-right: 16,
    ));
    @include mq(sp) {
      margin-right: mycalcSP(16);
    }

    svg {
      display: block;
      @include mq(sp) {
        width: mycalcSP(40);
        height: mycalcSP(40);
      }

      path {
        fill: $c_blue;
        .studio & {
          fill: $c_brand;
        }
        .academy & {
          fill: $c_brand;
        }
      }
    }
  }
}

.ttl_logo {

  &_img {
    svg {
      @include responsive((
        width: 728,
        height: 65,
        margin-right: 24,
      ));

      @include mq(sp) {
        width: mycalcSP(672);
        height: mycalcSP(60);
      }
    }
  }
}

.ttl_reccomend {
  padding-left: 0;
  @include responsive((
    margin-bottom: 96,
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(64);
  }
  &_txt {
    @include responsive((
      border-bottom-width: 4,
    ));
    @include mq(sp) {
      width: auto;
      max-width: mycalcSP(590);
      border-bottom-width: mycalcSP(4);
    }
    border-bottom-style: dashed;
    color: $c_blue;
    border-color: $c_blue;
    .studio & {
      color: $c_brand;
      border-color: $c_brand;
    }
    .academy & {
      color: $c_brand;
      border-color: $c_brand;
    }
  }

  &_img {
    svg {
      @include responsive((
        width: 80,
        height: 80,
      ));

      @include mq(sp) {
        width: mycalcSP(64);
        height: mycalcSP(64);
      }
    }
  }
}

.ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 0;
  @include responsive((
    margin-bottom: 128,
    padding: 208 0,
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(96);
    padding: mycalcSP(64 0);
  }

  &_ja {
    font-weight: 400;
    letter-spacing: .1em;
    position: relative;
    z-index: 1;
    @include responsive((
      font-size: 48,
      line-height: 64,
    ));
    @include mq(sp) {
      font-size: mycalcSP(40);
      line-height: mycalcSP(64);
    }
  }

  &_en {
    order: -1;
    font-family: $f_en;
    font-weight: 400;
    position: absolute;
    letter-spacing: .1em;
    color: $c_blue;
    top: 0;
    z-index: 0;
    white-space: nowrap;
    @include responsive((
      font-size: 480,
      line-height: 480,
    ));
    @include mq(sp) {
      font-size: mycalcSP(192);
      line-height: mycalcSP(192);
      letter-spacing: 0;
    }
  }
}

