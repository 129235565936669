/*
 * kv.scss
 *
 * キービジュアル用コンポーネント
 * 主にトップページで利用。
 */

.kv {
  width: 100%;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  @include responsive((
    padding-top: 84,
    padding-left: 104,
    margin-bottom: 0,
  ));
  @include mq(sp) {
    padding-top: mycalcSP(84);
    padding-left: 0;
    margin-bottom: mycalcSP(0);
  }

  &_headline {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    z-index: 2;
    @include responsive((
      width: 600,
      height: 36,
      margin-left: -240,
      top: 820,
    ));
    @include mq(sp) {
      width: mycalcSP(388);
      height: mycalcSP(24);
      margin-left: mycalcSP(-194);
      top: mycalcSP(660);
    }

    &_ttl {
      width: 100%;
      height: 100%;

      &_img {
        width: 100%;
        height: auto;
      }
    }

  }

  &_figure {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 0;
    margin: 0 auto;
    @include responsive((
      width: 2282,
      height: 1772,
      padding: 100,
      margin-top: 220,
    ));
    @include mq(sp) {
      padding: mycalcSP(0);
      width: mycalcSP(668);
      height: mycalcSP(1038);
      margin-top: mycalcSP(220);
      padding-top: mycalcSP(44);
      padding-bottom: mycalcSP(44);
    }

    &_img {
      max-width: none;
      @include responsive((
        width: 2282,
        height: 1772,
      ));
      @include mq(sp) {
        width: mycalcSP(668);
        height: mycalcSP(1038);
      }
    }
  }

}

.awaji {
  margin: 0 auto;
  @include responsive((
    width: 366,
    height: 416,
    margin-bottom: 640,
    padding-right: 68,
  ));
  @include mq(sp) {
    width: mycalcSP(268.8);
    height: mycalcSP(332.8);
    margin-bottom: mycalcSP(240);
    padding-right: mycalcSP(96);
  }

  &_figure_img {
    width: 100%;
  }
}