.breadcrumb_list_item_link,
.footer_nav_list_item_parent_link,
.footer_nav_list_item_parent a {
  @include textGradient($c_text, $c_blue, '> span', $p_slide);

  .studio > & {
    @include textGradient($c_text, $c_brand, '> span', $p_slide);
  }

  .academy > & {
    @include textGradient($c_text, $c_brand, '> span', $p_slide);
  }
}

.breadcrumb_list_item_link {
  @include textGradient($c_text, $c_blue, '> span', $p_slide);
}

.footer_bottom_list_item a {
  @include textGradient($c_text, $c_blue, false, $p_slide);
}



@keyframes Humberger_bar_z {
  0% {
    width: mycalcSP(40);
    height: mycalcSP(2);
    top: 0;
    margin-top: 0;
    left: 0;
    margin-left: 0;
    border-radius: mycalcSP(10);
  }
  20% {
    width: mycalcSP(4);
    height: mycalcSP(2);
    top: 0;
    margin-top: 0;
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
  }
  40% {
    top: 0;
  }
  60% {
    width: mycalcSP(4);
    height: mycalcSP(2);
    top: 30%;
    margin-top: 0;
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(-25deg);
  }
  80% {
    width: mycalcSP(40);
    height: mycalcSP(2);
    top: 50%;
    left: 0;
    margin-top: mycalcSP(-2);
    border-radius: mycalcSP(10);
    transform: rotate(-15deg);
  }
  90% {
    transform: rotate(-30deg);
  }
  100% {
    width: mycalcSP(40);
    height: mycalcSP(2);
    top: 50%;
    left: 0;
    margin-top: 0;
    border-radius: mycalcSP(10);
    transform: rotate(-25deg);
  }
}

@keyframes Humberger_bar_x {
  0% {
    width: mycalcSP(40);
    height: mycalcSP(2);
    top: 50%;
    margin-top: mycalcSP(-1);
    left: 0;
    border-radius: mycalcSP(10);
  }
  40% {
    width: mycalcSP(4);
    height: mycalcSP(2);
    top: 50%;
    margin-top: mycalcSP(-1);
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
  }
  70% {
    width: mycalcSP(20);
    height: mycalcSP(20);
    top: 50%;
    margin-top: mycalcSP(-10);
    left: 50%;
    margin-left: mycalcSP(-10);
    border-radius: 50%;
  }
  100%{
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    border-radius: 50%;
  }
}

@keyframes Humberger_bar_y {
  0% {
    width: mycalcSP(40);
    height: mycalcSP(2);
    bottom: 0;
    margin-bottom: 0;
    right: 0;
    margin-left: 0;
    border-radius: mycalcSP(10);
  }
  20% {
    width: mycalcSP(4);
    height: mycalcSP(2);
    bottom: 0;
    margin-bottom: 0;
    right: 50%;
    margin-right: mycalcSP(-2);
    border-radius: 50%;
  }
  40% {
    bottom: 0;
  }
  60% {
    width: mycalcSP(4);
    height: mycalcSP(2);
    bottom: 30%;
    margin-bottom: 0;
    right: 50%;
    margin-right: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(25deg);
  }
  80% {
    width: mycalcSP(40);
    height: mycalcSP(2);
    bottom: 50%;
    margin-bottom: mycalcSP(-2);
    right: 0;
    border-radius: mycalcSP(10);
    transform: rotate(35deg);
  }
  90% {
    transform: rotate(20deg);
  }
  100% {
    width: mycalcSP(40);
    height: mycalcSP(2);
    bottom: 50%;
    margin-bottom: mycalcSP(-2);
    right: 0;
    border-radius: mycalcSP(10);
    transform: rotate(25deg);
  }
}

@keyframes Humberger_bar_z_rev {
  0% {
    width: mycalcSP(40);
    height: mycalcSP(-2);
    top: 50%;
    left: 0;
    margin-top: 0;
    border-radius: mycalcSP(10);
    transform: rotate(-25deg);
  }
  20% {
    width: mycalcSP(4);
    height: mycalcSP(2);
    top: 30%;
    margin-top: 0;
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
  }
  45% {
    top: 0;
  }
  55% {
    width: mycalcSP(4);
    height: mycalcSP(2);
    top: 0;
    margin-top: 0;
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(10deg);
  }
  80% {
    width: mycalcSP(40);
    height: mycalcSP(2);
    top: 0;
    margin-top: 0;
    left: 0;
    margin-left: 0;
    border-radius: mycalcSP(10);
    transform: rotate(-3deg);
  }
  100% {
    width: mycalcSP(40);
    height: mycalcSP(2);
    top: 0;
    margin-top: 0;
    left: 0;
    margin-left: 0;
    border-radius: mycalcSP(10);
    transform: rotate(0deg);
  }
}

@keyframes Humberger_bar_x_rev {
  0% {
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    border-radius: 50%;
  }
  30% {
    width: mycalcSP(20);
    height: mycalcSP(20);
    top: 50%;
    margin-top: mycalcSP(-10);
    left: 50%;
    margin-left: mycalcSP(-10);
    border-radius: 50%;
  }
  60%{
    width: mycalcSP(4);
    height: mycalcSP(2);
    top: 50%;
    margin-top: mycalcSP(-1);
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
  }
  100%{
    width: mycalcSP(40);
    height: mycalcSP(2);
    top: 50%;
    margin-top: mycalcSP(-1);
    left: 0;
    border-radius: mycalcSP(10);
  }
}

@keyframes Humberger_bar_y_rev {
  0% {
    width: mycalcSP(40);
    height: mycalcSP(2);
    bottom: 50%;
    margin-bottom: mycalcSP(-2);
    right: 0;
    border-radius: mycalcSP(10);
    transform: rotate(25deg);
  }
  20% {
    width: mycalcSP(4);
    height: mycalcSP(2);
    bottom: 30%;
    margin-bottom: 0;
    right: 50%;
    margin-right: mycalcSP(-2);
    border-radius: 50%;
  }
  45% {
    bottom: 0;
  }
  55% {
    width: mycalcSP(4);
    height: mycalcSP(2);
    bottom: 0;
    margin-bottom: 0;
    right: 50%;
    margin-right: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(-10deg);
  }
  80% {
    width: mycalcSP(40);
    height: mycalcSP(2);
    bottom: 0;
    margin-bottom: 0;
    right: 0;
    margin-left: 0;
    border-radius: mycalcSP(10);
    transform: rotate(3deg);
  }
  100% {
    width: mycalcSP(40);
    height: mycalcSP(2);
    bottom: 0;
    margin-bottom: 0;
    right: 0;
    margin-left: 0;
    border-radius: mycalcSP(10);
    transform: rotate(0deg);
  }
}

@keyframes Humberger_bg {
  0% {
    background: $c_blue;
    border-radius: 0 0 mycalcSP(72) mycalcSP(72);
    transform: translate(0%, -100%);
  }
  10% {
    background: $c_blue;
    border-radius: 0 0 50% 50%;
  }
  80% {
    background: $c_blue;
    border-radius: 0 0 50% 50%;
  }
  100% {
    background: $c_blue;
    border-radius: 0;
    transform: translate(0%, 0%);
  }
}

@keyframes Humberger_bg_rev {
  0% {
    background: $c_blue;
    border-radius: mycalcSP(72) mycalcSP(72) 0 0;
    transform: translate(0%, 0%);
  }
  20% {
    background: $c_blue;
    border-radius: mycalcSP(72) mycalcSP(72) 0 0;
  }
  40% {
    background: $c_blue;
    border-radius: 50% 50% 0 0;
  }
  100% {
    background: $c_blue;
    border-radius: 50% 50% 0 0;
    transform: translate(0%, 100%);
  }
}

@keyframes Humberger_bg_sakura {
  0% {
    transform: translate(0%, -100%);
  }
  10% {
    transform: translate(0%, -100%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

@keyframes Humberger_bg_sakura_rev {
  0% {
    transform: translate(0%, 0%);
  }
  10% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(0%, 100%);
  }
}

@keyframes TEXT-MASK {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes TEXT-MASK-OVER {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes TEXT-MASK-BACK {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes TEXT-MASK-OVER-BACK {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes TEXT-MASK-OVER-SLUSH {
  0% {
    background-position: 100% 0;
  }

  30% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes TEXT-MASK-OVER-LOOP {
  0% {
    background-position: 100% 0;
  }

  40% {
    background-position: -100% 0;
  }

  100% {
    background-position: 0 0;
  }
}
