/*
 * figure.scss
 *
 * 図形用コンポーネント
 * 定義する際は、接頭辞としてfigure_を付けること。
 */

.figure {
  &_circle {
    border-radius: 50%;
    overflow: hidden;
    @include responsive((
      width: 1120,
      height: 1120,
    ));
    @include mq(sp) {
      width: mycalcSP(640);
      height: mycalcSP(640);
    }
  }
}