/*
 * transition.scss
 *
 * ページ遷移用コンポーネント
 */


.transition {

  &_figure {
    opacity: 0;
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;

    &_img {

    }
  }
}