/*
Reset Stylesheet
v1.0
Last Updated: 2019/06/10
Author: Kaito Fukiage - https://bre-men.jp/
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  font-style: normal;
  font-weight: normal;
  vertical-align:baseline;
  background:transparent;
}

html {
  overflow-y: scroll;
}

body {
  line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
  display:block;
}

ol, ul, nav ul {
  list-style: none;
}

blockquote, q {
  quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content:'';
  content:none;
}

a {
  margin:0;
  padding:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}

/* change colours to suit your needs */
ins {
  background-color:#ff9;
  color:#000;
  text-decoration:none;
}

/* change colours to suit your needs */
mark {
  background-color:none;
  color:inherit;
  font-style:inherit;
  font-weight:inherit;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom:1px dotted;
  cursor:help;
}

table {
  border-collapse:collapse;
  border-spacing:0;
}

caption, th {
  text-align: left;
}

hr {
  display:block;
  width: 100%;
  height:1px;
  border:0;
  margin:1em 0;
  padding:0;
  background: #000;
}

input, textarea, select {
  vertical-align:middle;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
}

p {
  letter-spacing: 0em;
}

a {
  text-decoration: none;
  color: inherit;
}

a:focus {
  outline: none;
}
