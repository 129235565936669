/*
 * header.scss
 *
 * ヘッダー用コンポーネント
 */

.header {
  width: 100%;
  position: absolute;
  z-index: 101;
  //transition: transform $t_fade,  background-color $t_fade;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  mix-blend-mode: darken;
  @include responsive((
    padding: 48 80 0,
  ));
  @include mq(sp) {
    // height: mycalcSP(160);
    height: mycalcSP(124);
    padding: 0;
  }

  @media screen and (max-width: 1750px) {
    justify-content: flex-end;
  }

  &_logo {
    flex-shrink: 0;
    position: absolute;
    opacity: 1;
    z-index: 1;
    transition: transform $t_fade;
    transform-origin: left center;
    mix-blend-mode: darken;
    left: 50%;
    @include responsive((
      width: 718,
      height: 590,
      top: 48,
      margin-left: -359,
    ));
    @include mq(sp) {
      width: mycalcSP(420);
      height: auto;
      top: mycalcSP(120);
      margin-left: mycalcSP(-210);
      transform-origin: left top;
      transition: transform $t_fade 0s, opacity $t_fade .2s;

      .scrolled.open & {
        transform: scale(1) translate(mycalcSP(40), mycalcSP(0));
      }
    }

    &_link {
      @include flex(row, center, center, none, wrap);

      picture, svg, img {
        display: block;
        width: 100%;

        @include mq(sp) {
          max-width: none;
          height: auto;
        }
      }
    }
  }

}

