/*
 * box.scss
 *
 * 箱型デザイン用コンポーネント
 * 定義する際は、接頭辞としてbox_を付けること。
 */

@mixin box_card() {
  display: block;
  box-sizing: border-box;
  @include responsive((
    width: 672,
    margin: 0 48 80 0,
  ));
  @include mq(sp) {
    width: mycalcSP(528);
    margin: mycalcSP(0 48 64 0);
  }

  &_meta {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    .time {
      font-family: $f_en;
      @include responsive((
        font-size: 32,
        line-height: 48,
        margin-right: 48,
      ));
      @include mq(sp) {
        font-size: mycalcSP(28);
        line-height: mycalcSP(32);
        margin-right: mycalcSP(32);
      }
    }

    .tag {
      @include responsive((
        font-size: 32,
        line-height: 48,
      ));
      @include mq(sp) {
        font-size: mycalcSP(28);
        line-height: mycalcSP(32);
      }
    }
  }

  &_data {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
  }

  &_desc {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    order: -1;
    @include responsive((
      margin-bottom: 48,
      height: 96,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(32);
      height: auto;
    }

    &_ttl {
      font-weight: 400;

      @include responsive((
        font-size: 32,
        line-height: 48,
      ));
      @include mq(sp) {
        font-size: mycalcSP(30);
        line-height: mycalcSP(48);
      }
    }
  }

  &_thumb {
    order: -1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @include responsive((
      width: 672,
      height: 672,
      margin-bottom: 48,
    ));
    @include mq(sp) {
      width: mycalcSP(528);
      height: auto;
      margin-bottom: mycalcSP(32);
    }

    &_img {
      width: 100%;
      height: auto;
      display: block;
      transition: transform $t_hv;
    }
  }

  .btn {
    @include responsive((
      height: 80,
      margin: 40 0 0,
    ));
    @include mq(sp) {
      width: 100%;
      height: mycalcSP(80);
      margin: mycalcSP(40 0 0);
    }

    &_link {
      justify-content: center;
      @include responsive((
        padding: 0 32,
      ));
      @include mq(sp) {
        padding: mycalcSP(0 32);
      }

      &_txt {
        @include responsive((
          font-size: 26,
          line-height: 44,
        ));
        @include mq(sp) {
          font-size: mycalcSP(26);
          line-height: mycalcSP(44);
        }
        &:before,
        &:after {
          @include responsive((
            right: 24,
          ));
          @include mq(sp) {
            right: mycalcSP(24);
          }
        }
      }
    }
  }

  &_link {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    transition: filter $t_hv;
    @include responsive((
      width: 672,
    ));
    @include mq(sp) {
      width: 100%;
    }

    &:hover {
      .box_card_thumb_img {
        transform: scale(1.1);
      }
    }
  }

}


// 利用例
.box_card {
  @include box_card;
}